import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { createRef, useEffect, useState } from "react";
import { FormControl } from "../form-control";
import Input from "../input";
import Button from "../button";
import Checkbox from "../checkbox";
import { Select } from "../select/select";
import { createAccountValidationSchema } from "@app/validations";
import { CreateAccountRequest, Server } from "@app/types";
import useObservable from "@core/hooks/use-observable.hook";
import ServerService from "@app/services/http/server.service";
import AccountService from "@app/services/http/account.service";

function CreateAccountModal({
  portalDialogRef,
  portalData,
}: PortalDialogProps) {
  const formRef = createRef<FormikContextType<CreateAccountRequest>>();
  const [servers, setServers] = useState<Server[]>([]);
  const account = portalData?.account;
  const [currentListServer, setCurrentListServers] = useState(
    account?.serversAccess ? [...account.serversAccess] : []
  );
  const [currentListAction, setCurrentListAction] = useState(
    account?.actionPcManagement ? [...account.actionPcManagement] : []
  );

  const listActionPcManagement = [
    "reset",
    "wol",
    "shutdown",
    "reconnect",
    "edit",
  ];

  const initialValues = {
    username: account?.username || "",
    password: "",
    email: account?.email || "",
    fullName: account?.fullName || "",
    phone: account?.phone || "",
    role: account?.role || "",
    serversAccess: account?.serversAccess || [],
    actionPcManagement: account?.actionPcManagement || [],
  };

  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  const handleSubmit = (values: CreateAccountRequest) => {
    const createAccountReq: CreateAccountRequest = {
      username: values.username,
      password: values.password,
      email: values.email,
      fullName: values.fullName,
      phone: values.phone,
      role: values.role,
      serversAccess: values.serversAccess,
      actionPcManagement: values.actionPcManagement,
    };

    if (account) {
      subscribeOnce(
        AccountService.editAccountStaff(account._id, createAccountReq),
        (account) => {
          portalDialogRef.close({ account });
        }
      );
    } else {
      subscribeOnce(
        AccountService.createAccount(createAccountReq),
        (account) => {
          portalDialogRef.close({ account });
        }
      );
    }
  };

  useEffect(() => {
    subscribeUntilDestroy(ServerService.getServers(), (servers) => {
      if (servers?.length) {
        setServers(servers);
      } else {
        setServers([]);
      }
    });
  }, []);

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[500px] p-5 max-sm:w-[300px]">
        <div className="font-bold text-xl">Tạo tài khoản</div>
        <Formik
          initialValues={initialValues}
          validationSchema={createAccountValidationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
        >
          {({ values }) => (
            <Form>
              <div className="mt-2">
                <div className="text-sm">Username</div>
                <FormControl name="username">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>
              <div className="mt-2">
                <div className="text-sm">Password</div>
                <FormControl name="password">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                    type="password"
                  />
                </FormControl>
              </div>
              <div className="mt-2">
                <div className="text-sm">Email</div>
                <FormControl name="email">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>
              <div className="mt-2">
                <div className="text-sm">Full name</div>
                <FormControl name="fullName">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>
              <div className="mt-2">
                <div className="text-sm">Phone</div>
                <FormControl name="phone">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>
              <div className="flex justify-between items-center">
                <div className="mt-2">
                  <div className="text-sm">Role (Quyền truy cập)</div>
                  <FormControl name="role">
                    <Select
                      width="fit-content"
                      options={[
                        { label: "Nhân viên", value: "staff" },
                        { label: "User", value: "user" },
                      ]}
                    />
                  </FormControl>
                </div>
                <div className="mt-2">
                  <div className="text-sm">Quyền truy cập các server</div>
                  <FormControl name="serversAccess">
                    <div className="flex gap-4">
                      {servers.length &&
                        servers.map((server) => {
                          return (
                            <Checkbox
                              key={server._id}
                              value={server._id}
                              checked={
                                currentListServer?.includes(server._id)
                                  ? true
                                  : false
                              }
                              label={server.name}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  values.serversAccess.push(server._id);
                                  setCurrentListServers((pre: string[]) => [
                                    ...pre,
                                    server._id,
                                  ]);

                                  return true;
                                } else {
                                  const idx = values.serversAccess.indexOf(
                                    server._id
                                  );

                                  idx >= 0 &&
                                    values.serversAccess.splice(idx, 1);

                                  setCurrentListServers((pre: string[]) => {
                                    const idx = pre.indexOf(server._id);

                                    idx >= 0 && pre.splice(idx, 1);

                                    return [...pre];
                                  });

                                  return false;
                                }
                              }}
                            />
                          );
                        })}
                    </div>
                  </FormControl>
                </div>
              </div>
              <div className="mt-2">
                <div className="text-sm">Quyền bấm các nút</div>
                <FormControl name="actionPcManagement">
                  <div className="flex gap-2 flex-wrap">
                    {listActionPcManagement.length &&
                      listActionPcManagement.map((action) => {
                        return (
                          <Checkbox
                            key={action}
                            value={action}
                            checked={
                              currentListAction?.includes(action) ? true : false
                            }
                            label={action}
                            onChange={(e) => {
                              if (e.target.checked) {
                                values.actionPcManagement.push(action);
                                setCurrentListAction((pre: string[]) => [
                                  ...pre,
                                  action,
                                ]);

                                return true;
                              } else {
                                const idx =
                                  values.actionPcManagement.indexOf(action);

                                idx >= 0 &&
                                  values.actionPcManagement.splice(idx, 1);

                                setCurrentListAction((pre: string[]) => {
                                  const idx = pre.indexOf(action);

                                  idx >= 0 && pre.splice(idx, 1);

                                  return [...pre];
                                });

                                return false;
                              }
                            }}
                          />
                        );
                      })}
                  </div>
                </FormControl>
              </div>
              <Button
                type="submit"
                label={account ? "Update" : "Thêm"}
                containerClassName="mt-5 mx-auto"
                width="fit-content"
                className="px-6 text-sm"
                labelClassName="font-bold"
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default CreateAccountModal;
